import React from 'react'
import moment from 'moment'

import Translations from './translations'
import Countries from './countries'
import PressProgram from '../../../components/program/pressProgram'
import { trackAdobeAnalyticsPressPreview } from '../../../core/services/tracking'

import Actions from '../actions'

import appConfig from 'config'

export const MTV_DATE_FORMAT = "DD.MM.YYYY, HH:mm";

export function getConstrainedDate(d) {
	const date = moment(d);
	const min = moment().subtract(50, "days");
	if (date.isBefore(min))
		return min;

	const max = moment().add(50, "days");
	if (date.isAfter(max))
		return max;

	return date;
}

export function getItemHref({ type, program = {}, link }) {
	if (link && link.url !== "") {
		return link.url;
	}

	let href = null;
	switch (type) {
		case "program":
			const { type: programType, seriesId, seasonId, episodeId, programId } = program;
			if (["Episode", "Season", "Series"].includes(programType)) {
				const url_series = Translations.getTranslation("url_series");
				const seriesPart = seriesId ? `/${url_series}/${seriesId}` : "";
				const url_season = Translations.getTranslation("url_season");
				const seasonPart = seasonId ? `/${url_season}/${seasonId}` : "";
				const url_episode = Translations.getTranslation("url_episode");
				const episodePart = episodeId ? `/${url_episode}/${episodeId}` : "";
				href = `${seriesPart}${seasonPart}${episodePart}`;
				break;
			}

			const url_program = Translations.getTranslation("url_program");
			href = `/${url_program}/${programId}`;
	}

	return href?.length > 0 && !href.includes("undefined") ? href : null;
}

export function getProgramLink(seriesId, seasonId, id) {
	const url_series = Translations.getTranslation("url_series");
	const url_season = Translations.getTranslation("url_season");
	const url_episode = Translations.getTranslation("url_episode");
	const url_program = Translations.getTranslation("url_program");

	const episodePart = id ? `/${url_episode}/${id}` : "";
	if (seriesId && seasonId) {
		return `/${url_series}/${seriesId}/${url_season}/${seasonId}${episodePart}`;
	} else if (seriesId) {
		return `/${url_series}/${seriesId}`;
	} else if (seasonId) {
		return `/${url_season}/${seasonId}${episodePart}`;
	}
	return `/${url_program}/${id}`;
}

export function getProgram(type, item, loggedIn, params) {
	const season = type === "season" ? item.season : null;
	const single = type === "single" ? item.program : null;

	if (!item.isLoading && (type === "season" && params.seasonId && (!season || !season.id) || type === "single" && (!single || !single.id))) {
		return <h1 style={{ textAlign: "center" }}>{Translations.getTranslation("programNotAvailable")}</h1>;
	}

	const program = season || single;
	program.productionCountries = translateProductionCountries(program.productionCountries);

	const props = type === "season"
		? {
			season: season,
			episodes: season.episodes,
			seriesId: params.seriesId,
			series: season.series || item.series,
			seasonId: params.seasonId,
			id: params.id,
			seasons: season.series?.seasons,
			getSeasonText: (seasonTitle, label, productionYear, seasonNumber) => seasonTitle || label || seasonNumber && `${Translations.getTranslation("season")} ${seasonNumber}` || productionYear || "?",
		}
		: {
			program: single,
		};

	return (
		<PressProgram
			allowPressImageDownload={loggedIn}
			allowPressImagePreview={loggedIn}
			programSchedules={item.programSchedules}
			onPressImageClick={loggedIn ? onPressImageClick : null}
			texts={Translations.getAllTranslations()}
			getProgramLink={getProgramLink}
			timezone={appConfig.features.pressTimezone}
			programReviews={item.programReviews}
			{...props}
		/>
	);
}

export function translateProductionCountries(countries) {
	if (countries && appConfig.features.pressLocale) {
		return countries.map(c => Countries[c]?.[appConfig.features.pressLocale] ?? c);
	}
	return countries;
}

function onPressImageClick(e, asset, pageName) {
	e.stopPropagation();
	e.preventDefault();
	Actions.previewAsset(asset);

	if (pageName) {
		trackAdobeAnalyticsPressPreview(pageName);
	}
}

export function getStringWithoutHTML(html) {
	const div = document.createElement("div");
	if (html?.length) {
		div.innerHTML = html;
	}
	return div.innerText;
}

export function shouldShowItemForUserBasedOnAudience(audience, loggedIn) {
	switch (audience) {
		case "Everyone":
			return true;
		case "Guest":
			return !loggedIn;
		case "LoggedInOnly":
		case "Subscribers":
		case "LoggedInNoSubscriber":
			return loggedIn;
		default:
			return true;
	}
}