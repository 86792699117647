import React from 'react'
import { browserHistory } from 'browserHistory'

import App from '../../components/app'
import Header from './header'
import Footer from './footer'
import AssetPreview from './shared/assetPreview'
import ErrorBoundary from '../../components/errorBoundary'

import Actions from './actions'
import Store from './store'
import UserStore from '../../core/authentication/store'
import Translations from './shared/translations'

import appConfig from 'config'

import './press.css'

export default class PressWrapperApp extends React.Component {

	state = {
		...Store.getState(),
		loggedIn: UserStore.getState().loggedIn,
		user: UserStore.getState().user,
	}

	componentDidMount() {
		Store.listen(this.onChange);
		UserStore.listen(this.onUserStoreChange);

		Actions.fetchItem("siteStructure");
		Actions.fetchChannelGroups();

		this.checkIfLoggedInAndMaybeRedirect();
	}

	componentDidUpdate() {
		this.checkIfLoggedInAndMaybeRedirect();
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
		UserStore.unlisten(this.onUserStoreChange);
	}

	onChange = state => this.setState(state);
	onUserStoreChange = ({ loggedIn, user }) => this.setState({ loggedIn, user });

	checkIfLoggedInAndMaybeRedirect = () => {
		const isOnLoginPage = this.props.location.pathname === "/login"
			|| this.props.location.pathname === `/${Translations.getTranslation("url_login")}`;
		if (
			!isOnLoginPage
			&& appConfig.features.pressRequireLogin
			&& !this.state.loggedIn
		) {
			browserHistory.replace("/login");
		}
	}

	render() {
		const siteStructure = this.state.item.siteStructure;

		// Trying ErrorBoundary here. Not sure if this is how it's supposed to be used. But this will prevent errors in a page from crashing the entire Press app.
		// The user will be able to navigate to a different page after the error occured.
		return (
			<App name="c6-press" isLoading={!!this.state.isLoading}>
				{(!appConfig.features.pressRequireLogin || this.state.loggedIn) && (
					<ErrorBoundary>
						<Header pages={siteStructure.sections?.filter(s => !!s)} loggedIn={this.state.loggedIn} user={this.state.user} searchResults={this.state.list.discovery} />
					</ErrorBoundary>
				)}
				<ErrorBoundary key={this.props.location.pathname}>
					{siteStructure ? this.props.children : null}
				</ErrorBoundary>
				{appConfig.features.pressEnableFooter && (
					<ErrorBoundary>
						<Footer />
					</ErrorBoundary>
				)}
				<ErrorBoundary>
					<AssetPreview asset={this.state.previewAsset} />
				</ErrorBoundary>
			</App>
		);
	}
}