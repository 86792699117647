import { get, put, post, patch, del } from '../core/c6'

import appConfig from 'config'

const API = "schedules";
const EPG_API = "epg";

/* **************** Schedule Changes ***************** */
export const fetchSchedules = filters => get(API, "schedules", filters);
export const fetchSchedule = id => get(API, `schedules/${id}`);
export const approveSchedule = payload => put(API, "schedules/approve", payload);
export const approveAll = payload => put(API, "schedules/approve/all", payload);
export const approveMany = payload => put(API, "schedules/approve/many", payload);
export const updateSchedule = payload => put(API, `schedules/update?channel=${payload.channel}&from=${payload.from}&until=${payload.until}&days=${payload.days ?? ""}`);

/* **************** Channels ***************** */
export const fetchChannels = (filters) => get(API, "channels", filters);

/* **************** Schedule Releases (EPG) ***************** */
export const fetchScheduleReleases = filters => get(EPG_API, "scheduleReleases", filters);
export const fetchScheduleRelease = ({id}) => get(EPG_API, `scheduleReleases/${id}`);

export const createScheduleRelease = payload => post(EPG_API, "scheduleReleases", payload);
export const updateScheduleRelease = ({id}, payload) => put(EPG_API, `scheduleReleases/${id}`, payload);
export const deleteScheduleRelease = ({id}) => del(EPG_API, `scheduleReleases/${id}`);

/* **************** Channel bundles (EPG) ***************** */
export const fetchChannelBundles = filters => get(EPG_API, "bundles", filters);

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);