import React, { Component } from 'react'
import { Link } from 'react-router'
import debounce from 'lodash/debounce'
import { useQuery } from '@tanstack/react-query'
import MiniSearch from 'minisearch'

import { SearchItem } from '../pages/search'

import Actions from '../actions'
import Translations from '../shared/translations'
import * as PressAPI from '../../../apis/press'
import { getProgramLink } from '../shared/utils'

import appConfig from 'config'

import './search.css'

export default class PressSearch extends Component {

	state = {
		searchText: "",
	}

	onContainerClick = e => {
		if (this.input) {
			this.input.focus();
		}
		if (this.props.onSearchClick) {
			this.props.onSearchClick(e);
		}
	}

	onClearClick = e => {
		e.stopPropagation();
		this.setState({ searchText: "" });
		if (this.input) {
			this.input.blur();
			this.input.value = "";
		}
	}

	onSearchResultClick = e => {
		e.stopPropagation();
		this.setState({ searchText: "" });
		if (this.input) {
			this.input.blur();
			this.input.value = "";
		}
	}

	search = e => {
		if (e.type === "keydown" || e.target.value.length === 0) {
			this.searchWithDebounce.cancel(); // Cancel the debounced call since we're doing it right now
			this.handleSearch(e);
		} else {
			e.persist();
			this.searchWithDebounce(e);
		}
	}

	handleSearch = e => {
		const searchText = e.target.value;
		this.setState({ searchText });
		if (!appConfig.features.pressNewSearch) {
			const { latestSearchPayload } = this.props.searchResults;

			if (searchText && searchText.length > 1 && (!latestSearchPayload || latestSearchPayload.searchText !== searchText)) { // TODO: Can't search for 'V' :(
				Actions.discoverySearch({}, searchText);
			}
		}
	}

	searchWithDebounce = debounce(this.handleSearch, 500);

	render() {
		const { searchText } = this.state;
		const content = (
			<>
				<div className="input-wrapper">
					<SearchIcon />
					<input
						type="search"
						placeholder={`${Translations.getTranslation("search")}...`}
						onChange={this.search}
						defaultValue={searchText}
						ref={el => this.input = el}
						maxLength={100}
					/>
					<button onClick={this.onClearClick}>×</button>
				</div>
				<div className="results">
					<SearchResults
						searchText={searchText}
						onSearchItemClick={this.onSearchResultClick}
					/>
					{/* renderDiscoverySearchResults(this.props.searchResults, this.onSearchResultClick, searchText)} */}
				</div>
			</>
		);

		let className = "c6-press-search c6-press-search-new";
		if (!searchText || !searchText.length) {
			className += " empty";
		}

		return (
			<div className={className} onClick={this.onContainerClick}>
				{content}
			</div>
		);
	}
}

const SearchIcon = () => (
	<svg viewBox="0 0 24 24">
		<title>{Translations.getTranslation("search")}</title>
		<path d="M19.4,20.8c0.6,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7l-4.3-4.3c-0.4-0.4-1-0.4-1.4,0c-2.2,2.2-5.8,2.2-8,0  s-2.2-5.8,0-8s5.8-2.2,8,0c1.5,1.5,2,3.7,1.4,5.7c-0.1,0.5,0.2,1.1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.6c1.3-4-0.9-8.3-4.9-9.6  S4.4,4.5,3.2,8.5s0.9,8.3,4.9,9.6c2.4,0.8,5,0.3,7-1.2l3.6,3.6C18.9,20.7,19.1,20.8,19.4,20.8z"></path>
	</svg>
);

// TODO: debounce!
function SearchResults({ searchText, onSearchItemClick }) {
	const a2zData = useA2Z();
	const searchResults = getSearchResults(searchText, a2zData);
    let content = null;
    if (!searchResults.length) {
        content = (
            <div className="search-status">{Translations.getTranslation("noItemsFound")}</div>
        );
    } else {
        content = searchResults?.map((item) => {
            const image = item.program?.assets?.find(a => a.type === "image" && a.contentType === "Series")
                ?? item.program?.assets?.find(a => a.type === "image" && a.contentType === "Single");
            return (
                <SearchItem
                    key={item.id}
                    link={getProgramLink(item.program?.seriesId, null, item.program?.programId)}
                    title={item.program?.seriesTitle ?? item.program?.title}
                    image={image}
					onClick={onSearchItemClick}
                />
            );
        });
    }

    return (
        <div className="c6-press-search-searchresults">
            {content}
        </div>
    );
}

const renderDiscoverySearchResults = ({ isLoading, latestSearchPayload, numberOfItems, items }, onClick, searchText) => {
	if (isLoading || latestSearchPayload && latestSearchPayload.searchText !== searchText)
		return <div className="results-item">{Translations.getTranslation("searching")}...</div>;
	if (latestSearchPayload && numberOfItems === 0)
		return <div className="results-item">{Translations.getTranslation("noItemsFound")}.</div>;
	
	return [...items].map(r => {
		const { title, pathname } = getHrefForDiscoveryItem(r);

		return (
			<Link key={r.id || r.reference} to={pathname} className="results-item" onClick={onClick}>
				{title}
			</Link>
		);
	});
}

function getHrefForDiscoveryItem({ type, reference, name, json, series, seasonNumber }) {
	const url_series = Translations.getTranslation("url_series");
	const url_program = Translations.getTranslation("url_program");
	let title;
	try {
		const data = JSON.parse(json);
		const version = data.programVersions.find(v => v.language === appConfig.features.pressLocale) || data.programVersions[0];
		title = version.title && version.title.length
			? version.title
			: data.originalTitle;
	} catch (e) {
		console.error(e);
		title = name;
	}
	switch (type) {
		case "SingleProgram":
			return { pathname: `/${url_program}/${reference}`, title };
		case "SeriesProgram":
			return { pathname: `/${url_series}/${reference}`, title };
	}
}

function useA2Z() {
    const { data } = useQuery(
        ["c6-press-search", "a2z"],
        () => {
            return new Promise((resolve) => {
                (async () => {
                    const lists = await PressAPI.fetchA2Z();

                    const flatList = lists?.reduce((result, list) => {
                        if (list?.items?.length) {
                            return [...result, ...list.items];
                        }
                        return result;
                    }, []);
                
                    resolve(flatList ?? []);
                })();
            });
        },
    );

    return data;
}

function getSearchResults(searchText, data) {
    if (!searchText?.length) {
        return [];
    }

    const text = searchText.toLowerCase();

    const miniSearch = new MiniSearch({
        fields: ["programTitle", "programCreditsName", "programSynopsisLong", "programSynopsisMedium", "programSynopsisShort"],
        extractField: (document, fieldName) => {
            if (fieldName === "programTitle") {
                return document.program?.title;
            }

            if (fieldName === "programCreditsName") {
                return document.program?.credits?.map(c => c.name).join(" ");
            }

            if (fieldName === "programSynopsisLong") {
                return document.program?.synopsis?.long;
            }

            if (fieldName === "programSynopsisMedium") {
                return document.program?.synopsis?.medium;
            }

            if (fieldName === "programSynopsisShort") {
                return document.program?.synopsis?.short;
            }

            return document[fieldName];
        },
        storeFields: ["id", "program"],
        searchOptions: {
            boost: { programTitle: 1000, programCreditsName: 100, programSynopsisLong: 1, programSynopsisMedium: 1, programSynopsisShort: 1 },
            prefix: true,
            combineWith: "AND",
        }
    });

    miniSearch.addAll(data ?? []);

    const results = miniSearch.search(text);
    
    return results;
}