import { useState } from 'react'
import groupBy from 'lodash/groupBy'
import { Link } from 'react-router'
import moment from 'moment'

import Widget from './widget'
import ResponsiveImage from '../../../components/assets/responsiveImage'
import Actions from '../actions'
import PressButton from '../shared/button'
import { fetchAssetDownloadLink } from '../../../apis/star'
import usePressProgram from '../../../core/queries/press/usePressProgram'
import usePressProgramReviews from '../../../core/queries/press/usePressProgramReviews'
import usePressProgramSchedules from '../../../core/queries/press/usePressProgramSchedules'
import { renderProgramReviews } from '../../../components/program/pressUtils'
import {
	Availability,
	FutureAvailability,
	getUpcomingBroadcasts,
	renderBroadcasts,
	renderNextUpcomingBroadcast,
} from '../../../components/program/utils'

import appConfig from 'config'

import './metadataListPremiere.css'

const MetadataListPremiere = (props) => {
	const { widgetData } = props;
	const { items } = widgetData;

	const previousMonth = moment().startOf("month").subtract(1, "month");
	const onlyPreviousMonthAndOnwards = items?.filter(item => moment(item.start).isSameOrAfter(previousMonth, "month"));
	const itemsGroupedByMonth = groupBy(onlyPreviousMonthAndOnwards, item => moment(item.start).format("YYYYMM"));

	return (
		<div className="c6-press-metadata-list-premiere">
			<div className="items">
				{Object.keys(itemsGroupedByMonth).map((monthKey) => {
					return (
						<div
							key={monthKey}
							id={monthKey}
							className="month"
						>
							<h2>
								PREMIÄRER {moment(itemsGroupedByMonth[monthKey][0].start).format("MMMM").toUpperCase()}
								<PressButton
									onClick={printMonth.bind(this, monthKey)}
									label="Skriv ut"
								/>
							</h2>
							{itemsGroupedByMonth[monthKey].map((item) => {
								return (
									<Item
										key={item.id}
										item={item}
									/>
								)
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
}

function Item(props) {
	const {
		item,
	} = props;

	const [expand, setExpand] = useState(false);
	const imageAssetId = item.program?.assets?.find(a => a.type === "image" && a.category === "main")?.id
		?? item.program?.assets?.find(a => a.type === "image" && a.type === "season")?.id
		?? item.program?.assets?.find(a => a.type === "image" && a.type === "series")?.id
		?? item.assets?.find(a => a.type === "image")?.id;
	const { genre, productionCountries, productionYear, credits, assets } = item.program || {};
	const productionInfo = [genre, productionCountries, productionYear].filter(Boolean).join('. ');
	const itemTitle = item.heading ?? item.program?.title;
	const itemDescription = item.description ?? item.program?.synopsis?.medium ?? item.program?.synopsis?.short;
	const programSynopsis = item.program?.synopsis?.long ?? item.program?.synopsis?.medium ?? item.program?.synopsis?.short;

	const { data: program } = usePressProgram(item.reference);
	const { data: programSchedules } = usePressProgramSchedules(item.reference);
	const upcomingBroadcasts = program && programSchedules && getUpcomingBroadcasts(programSchedules, program.type);
	const onlyIncludeBroadcastsWithinDays = 45;
	const { data: programReviews } = usePressProgramReviews(program?.imdb);
	
	return (
		<div
			key={item.id}
			className={`item ${expand ? "active" : ""}`}
		>
			<div
				className="main-info"
				onClick={() => setExpand(!expand)}
			>
				<div className="date">
					<div className="number">{moment(item.start).format("D")}</div>
					<div className="day-of-week">{moment(item.start).format("dddd")}</div>
				</div>
				<div className="image">
					<ResponsiveImage
						id={imageAssetId}
						sizes="(max-width: 800px) 300px, 600px"
					/>
				</div>
				<div className="info">
					<h3>{itemTitle}</h3>
					<p>{itemDescription}</p>
					{appConfig.features.pressEnableProgramReviews && renderProgramReviews({ data: programReviews, seasonNumber: program?.seasonNumber })}
				</div>
			</div>
			{expand && (
				<div className="extra-info">
					<div className="press-flex-info">
						<section className="press-left-info">
							<span>{programSynopsis}</span>
							<section className="press-image-area">
								{getPartnerImages(assets, "single", location)}
								{getPartnerImages(assets, "episode", location)}
								{getPartnerImages(assets, "season", location)}
								{getPartnerImages(assets, "series", location)}
							</section>
						</section>
						<section className="press-right-info">
							<span className="production">{productionInfo}</span>
							{credits && <div>{getCredits(credits, "actor")}</div>}
							{credits && <div>{getCredits(credits, "director")}</div>}
							<div className="availability-and-broadcasts">
								{program?.type === "season" && upcomingBroadcasts && renderNextUpcomingBroadcast(null, upcomingBroadcasts, {}, null, true)}
								{program?.type === "single" && upcomingBroadcasts && renderBroadcasts(program, programSchedules, {}, null, null, null, true, onlyIncludeBroadcastsWithinDays)}
								<Availability program={program} platform="tv4play" heading="På TV4 Play" skipLinkIfNoProgramId={true} />
								<FutureAvailability program={program} platform="tv4play" heading="Snart på TV4 Play" skipLinkIfNoProgramId={true} />
							</div>
						</section>
					</div>
					{appConfig.features.pressEnableProgramReviews && renderProgramReviews({ data: programReviews, seasonNumber: program?.seasonNumber })}
				</div>
			)}
		</div>
	);
}

function getCredits(credits, type) {
	const filteredPersons = credits.filter(person => person.type.toLowerCase() === type);

	if (!filteredPersons.length) {
		return null;
	}

	return (
		<div className="credits">
			{getCreditTypeHeading(type)}
			<div>
				<span>{filteredPersons.map(person => person.name).join(', ')}</span>
			</div>
		</div>
	);
}

const CREDIT_TYPE_HEADING_MAP = {
	"actor": "SKÅDESPELARE",
	"host": "VÄRD",
	"director": "REGISSÖR",
	"producer": "PRODUCENT",
	"scriptWriter": "MANUSFÖRFATTARE",
};
function getCreditTypeHeading(type) {
	if (!type) {
		return null;
	}
	
	return (<span className="credit-type">{CREDIT_TYPE_HEADING_MAP[type]}</span>);
}

function getPartnerImages(assets = [], contentType, location) {
	const pressImages = assets.filter(a => a.category === "partner" && a.type === "image" && a.contentType.toLowerCase() === contentType) || [];
	if (pressImages.length) {
		const images = pressImages.map((a) => (
			<Link
				key={a.id}
				to={generateLinkAction(a.id, location)}
				onClick={(a.type === "clip" || a.type === "image") && onPressImageClick ? e => onPressImageClick(e, a) : null}
				className="icon-search">
				<ResponsiveImage
					id={a.id}
					format="freeheight"
					sizes="(min-width: 1901px) 33.33vw, (min-width: 1200px) 50vw, (min-width: 786px) 25vw, (min-width: 666px) 33.33vw, 50vw" />
				<button onClick={handleClick.bind(this, a)} className="icon-cloud_download"><span>Download</span></button>
			</Link>
		));

		return <section className="press-images">{images}</section>
	}

	return null;
}

function onPressImageClick(e, asset) {
	e.stopPropagation();
	e.preventDefault();
	Actions.previewAsset(asset);
}

function generateLinkAction(id, location) {
	return {
		pathname: `/image/${id}`,
		state: {
			modal: true,
			wideModal: true,
			returnTo: `${location.pathname}${location.search}`,
		}
	};
}

async function handleClick(asset, e) {
	e.preventDefault();
	e.stopPropagation();

	const href = await fetchAssetDownloadLink(asset.id);
	const a = document.createElement("a");
	a.target = "_blank";
	a.href = href;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

function printMonth(monthKey) {
	document.querySelectorAll(".c6-press-metadata-list-premiere .month").forEach((month) => {
		if (month.id === monthKey) {
			month.classList.add("print-month");
			month.classList.remove("dont-print-month");
		} else {
			month.classList.add("dont-print-month");
			month.classList.remove("print-month");
		}
	});

	window.print();
}

export default Widget(MetadataListPremiere);