import React from 'react'
import Flatpickr from 'react-flatpickr'
import { Finnish } from "flatpickr/dist/l10n/fi.js"
import moment from 'moment-timezone'
import upperFirst from 'lodash/upperFirst'
import debounce from 'lodash/debounce'
import { browserHistory } from 'browserHistory'
import { Link } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import Schedule from '../../internal-schedule/schedule/schedule'
import Broadcast from '../../internal-schedule/schedule/broadcast'
import Checkbox from '../shared/checkbox'
import C6Search from '../../../components/ui/controls/search'
import Switch from '../../../components/ui/controls/switch'
import DropDown from '../../../components/ui/controls/dropdown'
import ResponsiveImage from '../../../components/assets/responsiveImage'

import Translations from '../shared/translations'
import { getProgramLink, translateProductionCountries } from '../shared/utils'
import * as PressAPI from '../../../apis/press'
import { isLoggedIn } from '../../../core/services/auth'
import { useSessionStorage } from '../../../core/hooks/useStorage'
import { copyHtmlToClipboard } from '../../../utils/misc'
import { getFacts } from '../../../components/program/metadata'

import Store from '../store'

import appConfig from 'config'

import './search.css'

const MAX_DATE_RANGE = 50;
const FinnishLocaleModified = {
    ...Finnish,
    rangeSeparator: " - ",
};
const DAY_FORMAT = "dddd D. MMMM[ta]";
const PRIME_TIME_START = 16;
const PRIME_TIME_END = 23; // 23:59

const Search = () => {
    if (!isLoggedIn()) {
        setTimeout(
            () => {
                browserHistory.push({
                    pathname: `/`,
                    state: location.pathname.endsWith("/logout") || location.pathname.endsWith("/login")
                        ? location.state ?? undefined
                        : { nextLocation: { ...location } },
                });
            },
        );
        return null;
    }
    
    const [schedules, setSchedules] = useSessionStorage("c6-press-search-schedules", []);
    const [vodViews, setVodViews] = useSessionStorage("c6-press-search-vod-views", []);
    const [dates, setDates] = useSessionStorage("c6-press-search-dates", getDatesBetween([
        moment().format("YYYY-MM-DD"),
        moment().add(MAX_DATE_RANGE, "days").format("YYYY-MM-DD"),
    ]));
    const tempDatesForFixingEmptyPicker = React.useRef();
    const [linearOrVod, setLinearOrVod] = useSessionStorage("c6-press-search-linear-or-vod", "linear");
    const [selectedChannelGroup, setSelectedChannelGroup] = useSessionStorage("c6-press-search-channel-group", "tv");
    const [selectedCategory, setSelectedCategory] = useSessionStorage("c6-press-search-category", null);
    const [searchTextVisual, setSearchTextVisual] = useSessionStorage("c6-press-search-searchtextvisual", "");
    const [searchTextActual, setSearchTextActual] = useSessionStorage("c6-press-search-searchtextactual", "");

    const [channelGroups, setChannelGroups] = React.useState([]);
    const [primetimeOnlyForChannels, setPrimetimeOnlyForChannels] = useSessionStorage("c6-press-search-primetime-channels", {});
    const togglePrimetimeOnlyForChannel = (channel, day) => {
        setPrimetimeOnlyForChannels(oldValue => ({
            ...oldValue,
            [`${channel.name}-${day}`]: !(oldValue[`${channel.name}-${day}`] ?? true),
        }));
    };

    const onDatesChange = (newDates) => {
        if (newDates?.length === 2) {
            setDates(getDatesBetween(newDates));
        }
        tempDatesForFixingEmptyPicker.current = newDates;
    };

    // When user closes the range picker with only one date selected, we choose the end date so that the picker input is not empty
    const onDatePickerClose = () => {
        if (tempDatesForFixingEmptyPicker.current?.length === 1) {
            const from = tempDatesForFixingEmptyPicker.current[0];
            const max = moment().add(MAX_DATE_RANGE, "days");
            const to = moment.min(moment(from).add(MAX_DATE_RANGE, "days"), max).format("YYYY-MM-DD");
            setDates(getDatesBetween([from, to]));
        } else {
            tempDatesForFixingEmptyPicker.current = null;
        }
    };

    const channels = channelGroups
        .filter(channelGroup => channelGroup.channels?.length && (!selectedChannelGroup || channelGroup.name === selectedChannelGroup))
        .map(channelGroup => channelGroup.channels)
        .flat()
        .map(channel => ({ key: channel.name }));
    const vodData = useVodViews(dates);
    const linearData = useSchedules(dates, channels);

    React.useEffect(
        () => {
            (async () => {
                // Don't update state unless we have data
                // This prevents cases where the user has data stored in sessionStorage but the empty data from useSchedules/useVodViews overwrites it, causing a flicker
                if (!linearData?.[0]?.[0]?.id) {
                    return;
                }
                
                const { schedules, vodViews } = getSchedulesAndVodViews(dates, vodData, linearData, selectedCategory, primetimeOnlyForChannels, searchTextActual);
                setSchedules(schedules);
                setVodViews(vodViews);
            })();
        },
        [dates, vodData, linearData, selectedCategory, primetimeOnlyForChannels, searchTextActual]
    );

    const updateSearchTextDebounced = React.useCallback(
        debounce((text) => {
            setSearchTextActual(text);
        }, 500)
    );

    React.useEffect(
        () => {
            // Channelgroups are loaded in PressWrapperApp
            const onChange = (state) => {
                const channelGroups = state.list?.channelGroups?.items;
                setChannelGroups(channelGroups);
            };
            onChange(Store.getState());
            Store.listen(onChange);

            return () => {
                Store.unlisten(onChange);
            };
        },
        []
    );

    console.log("schedules", schedules);
    console.log("vodViews", vodViews);

    return (
        <div className="c6-press-search-page c6-press-page no-sidebar c6-internal-schedule">
            <div className="header">
                <h1>Hae MTV:n ohjelmistosta</h1>
                <p>Voit hakea tulevia ohjelmia joko MTV:n kanavilta tai MTV Katsomosta valitsemallesi aikavälille.<br/>Hakua voi tarkentaa valitsemalla ohjelmaluokan sekä käyttämällä hakusanoja.</p>
                <div className="filters">
                    <div className="vod-linear-filters">
                        <div className="linear-filters">
                            <div>
                                <Checkbox
                                    label="MTV:n kanavien esitystiedot"
                                    checked={linearOrVod === "linear"}
                                    onChange={() => setLinearOrVod("linear")}
                                    radio={true}
                                />
                            </div>

                            <div className={`linear-sub-filters ${linearOrVod === "linear" ? "active" : ""}`}>
                                <div>
                                    <Switch
                                        name="channelGroup"
                                        title={Translations.getTranslation("channels")}
                                        onChange={(e) => setSelectedChannelGroup(e.target.value)}
                                        states={channelGroups.map(cg => ({ key: cg.name, text: cg.displayName }))}
                                        currentState={selectedChannelGroup}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="vod-filters">
                            <div>
                                <Checkbox
                                    label="MTV Katsomon esitystiedot"
                                    checked={linearOrVod === "vod"}
                                    onChange={() => setLinearOrVod("vod")}
                                    radio={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="dates-category-text">
                        <div>
                            <span>Aikaväli</span>
                            
                            <div className="date-picker-wrapper">
                                <Flatpickr
                                    options={{
                                        altInput: true,
                                        altFormat: "j.n",
                                        locale: FinnishLocaleModified,
                                        mode: "range",
                                        weekNumbers: true,
                                        maxDate: moment().add(MAX_DATE_RANGE, "days").toDate(),
                                    }}
                                    value={[dates[0], dates[dates.length - 1]]}
                                    onChange={onDatesChange}
                                    onClose={onDatePickerClose}
                                    onOpen={(_dates, _string, fp) => {
                                        fp.jumpToDate(_dates[0] ?? new Date());
                                    }}
                                />
                                <span className="icon-date_range"></span>
                            </div>
                        </div>

                        <div>
                            <DropDown
                                name="category"
                                title="Ohjelmaluokka"
                                onChange={(e, val) => setSelectedCategory(val)}
                                states={categoryOptions}
                                currentState={selectedCategory}
                            />
                        </div>

                        <div>
                            <span>Hae</span>
                            <C6Search
                                onChange={(e) => {
                                    const text = e.target.value;
                                    setSearchTextVisual(text);
                                    updateSearchTextDebounced(text);
                                }}
                                placeholder="Suodattaa..."
                                searchText={searchTextVisual}
                            />
                        </div>
                    </div>
                </div>  
            </div>

            <div className="schedules">
                {dates.map((day, index) => {
                    const schedule = schedules[index];
                    const assets = vodViews[index]?.assets;
                    return (
                        <div key={day} className="day">
                            <h1>{upperFirst(moment(day).format(DAY_FORMAT))}</h1>
                            {linearOrVod === "vod" && (
                                <VodView
                                    assets={assets}
                                />
                            )}
                            {linearOrVod === "linear" && schedule && (
                                <Schedule
                                    schedules={schedule}
                                    channels={[]}
                                    noBroadcastsText={`${Translations.getTranslation("noBroadcasts")}.`}
                                    getChannelHeaderExtra={(channel) => getChannelHeaderExtra(channel, day, togglePrimetimeOnlyForChannel, primetimeOnlyForChannels[`${channel.name}-${day}`] ?? true)}
                                    day={day}
                                    copyProgramInfoButtonText={Translations.getTranslation("copyProgramInfo")}
                                    copyScheduleForDay={copyScheduleForDay}
                                >
                                    <Broadcast
                                        showSeasonEpisodeNumbersForSports={false}
                                        programTitleMissingText={Translations.getTranslation("programTitleMissing")}
                                        getProgramLink={getProgramLink}
                                        fixedUtcOffset={moment().hours(4).isDST() ? 3 : 2} // DST ends on 2019-10-27 03:00 so we need to add 4 hours or we will get a false negative
                                        disableTooltip={true}
                                    />
                                </Schedule>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Search;

function VodView({ assets }) {
    if (!assets?.length) {
        return null;
    }

    return (
        <div className="c6-press-search-vod-view">
            <div className="assets">
                {assets.map((asset) => {
                    return (
                        <SearchItem
                            key={asset.id}
                            link={getProgramLink(asset.metadata?.series?.id, asset.metadata?.season?.id, asset.metadata?.id)}
                            title={getAssetTitle(asset)}
                            image={asset.metadata?.image}
                            renderPlus={!asset.rightTypes?.includes("avod")}
                            renderKatsomoLogo={true}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export function SearchItem({ link, title, image, renderPlus, renderKatsomoLogo, onClick }) {
    return (
        <Link
            className="c6-press-search-item"
            to={link}
            onClick={onClick}
            title={title}
        >
            {renderItemImage(image, renderPlus, renderKatsomoLogo)}
            <div className="asset-info">
                <span className="asset-title">{title}</span>
            </div>
        </Link>
    );
}

function getSchedulesAndVodViews(dates, vodData = [], linearData = [], category, primetimeOnlyForChannels, searchText) {
    const schedules = linearData
        .map(schedule => ([
            ...schedule.map(s => {
                const primetimeOnly = primetimeOnlyForChannels[`${s.channel.name}-${s.day}`] ?? true;
                return {
                    ...s,
                    broadcasts: filterBroadcasts(s.broadcasts, category, primetimeOnly, searchText),
                };
            }),
        ]));
    
    const vodViews = vodData
        .map((vodView, i) => {
            const day = dates[i];
            const notNullVodViewTypes = vodView.filter(vw => !!vw);
            const assets = notNullVodViewTypes.map(vw => {
                return vw.vodViewAssets?.map(a => ({
                    ...a,
                    rightTypes: [vw.rightType],
                    _hideBecauseCollapse: false,
                }));
            }).flat();
            const mergedAssets = mergeAssetsWithSameId(assets);
            const collapsedAssets = collapseAssetsInSameSeries(mergedAssets);
            const filteredAssets = filterAssets(collapsedAssets, true, category, searchText);
            return {
                day,
                assets: filteredAssets,
            };
        });
        
    return {
        schedules,
        vodViews,
    };
}

function getDatesBetween([startDate, endDate]) {
    let dates = [];
    const current = moment(startDate);
    while (current.isSameOrBefore(endDate)) {
        dates.push(current.format("YYYY-MM-DD"));
        current.add(1, "days");
    }
    return dates;
}

function filterBroadcasts(broadcasts, category, primeTimeOnly, searchText) {
    return broadcasts?.filter((broadcast) => {
        const start = moment(broadcast.public.start).tz(appConfig.features.pressTimezone);
        const end = moment(broadcast.public.end).tz(appConfig.features.pressTimezone);

        const isPrimetime = start.hour() >= PRIME_TIME_START && start.hour() <= PRIME_TIME_END
            || end.hour() > PRIME_TIME_START && end.hour() <= PRIME_TIME_END
            || end.hour() === PRIME_TIME_START && end.minute() !== 0;

        const isSearchMatch = searchText?.length
            ? broadcast.title?.toLowerCase().includes(searchText.toLowerCase())
            : true;

        return (!primeTimeOnly || isPrimetime)
            && isProgramCategory(broadcast.metadata, category)
            && isSearchMatch;
    }) ?? [];
}

function filterAssets(assets, premieresOnly, category, searchText) {
    return assets?.filter((asset) => {
        let isPremiere = false;
        if (asset.metadata?.type?.toLowerCase() === "episode") {
            isPremiere = asset.metadata?.episodeNumber === 1;
        } else {
            isPremiere = true;
        }

        const isSearchMatch = searchText?.length
            ? getAssetTitle(asset)?.toLowerCase().includes(searchText.toLowerCase()) || asset.name?.toLowerCase().includes(searchText.toLowerCase())
            : true;
        
        return !!asset.metadata
            && (!premieresOnly || isPremiere)
            && isProgramCategory(asset.metadata, category)
            && isSearchMatch;
    });
}

function mergeAssetsWithSameId(assets) {
    return assets.reduce((result, asset) => {
        const existingAsset = result.find(a => a.id === asset.id || a.metadata?.id === asset.metadata?.id);
        if (!existingAsset) {
            result.push(asset);
        } else {
            const existingAssetIndex = result.indexOf(existingAsset);
            result[existingAssetIndex] = {
                ...existingAsset,
                rightTypes: [...existingAsset.rightTypes, ...asset.rightTypes],
            };
        }

        return result;
    }, []);
}

function collapseAssetsInSameSeries(assets) {

    // Step 1: Sort assets by episode number
    const assetsSortedByEpisodeNumber = [];
    assets.forEach((asset) => {
        if (asset.metadata?.season?.id && assetsSortedByEpisodeNumber.find(a => a.metadata?.season?.id === asset.metadata?.season?.id)) {
            return;
        }

        if (asset.metadata?.type?.toLowerCase() !== "episode") {
            assetsSortedByEpisodeNumber.push(asset);
            return;
        }

        const assetsInSameSeason = asset.metadata?.season?.id && assets.filter(a => a.metadata?.season?.id === asset.metadata?.season?.id);
        if (!assetsInSameSeason.length) {
            assetsSortedByEpisodeNumber.push(asset);
            return;
        }

        const sortedAssetsInSeason = assetsInSameSeason.sort((a, b) => a.metadata?.episodeNumber - b.metadata?.episodeNumber);
        assetsSortedByEpisodeNumber.push(...sortedAssetsInSeason);
    });

    // Step 2: Collapse assets in the same season (add _collapsedEpisodeNumberEnd to the first episode, and _hideBecauseCollapse to the rest of the episodes in the same season)
    const collapsedAssets = [];
    assetsSortedByEpisodeNumber.forEach((asset) => {
        if (asset._hideBecauseCollapse) {
            return;
        }

        if (asset.metadata?.type?.toLowerCase() !== "episode") {
            collapsedAssets.push(asset);
            return;
        }

        const assetsInSameSeason = assetsSortedByEpisodeNumber.filter(a => a.metadata?.season?.id === asset.metadata?.season?.id);
        const assetsInSameSeasonWithEpisodeNumberHigherThanThisAsset = assetsInSameSeason.filter(a => a.id !== asset.id && a.metadata?.episodeNumber >= asset.metadata?.episodeNumber); // There can be episodes with the same episode number, for some reason
        if (!assetsInSameSeasonWithEpisodeNumberHigherThanThisAsset.length) {
            collapsedAssets.push(asset);
            return;
        }

        const collapsedAsset = {
            ...asset,
            _collapsedEpisodeNumberEnd: asset.metadata?.episodeNumber,
        };

        for (let i = 0; i < assetsInSameSeasonWithEpisodeNumberHigherThanThisAsset.length; i++) {
            const a = assetsInSameSeasonWithEpisodeNumberHigherThanThisAsset[i];
            if (
                a.metadata?.episodeNumber === collapsedAsset._collapsedEpisodeNumberEnd + 1
                || a.metadata?.episodeNumber === collapsedAsset._collapsedEpisodeNumberEnd // There can be episodes with the same episode number, for some reason
            ) {
                a._hideBecauseCollapse = true;
                collapsedAsset._collapsedEpisodeNumberEnd = a.metadata?.episodeNumber;
            } else {
                break;
            }
        }

        collapsedAssets.push(collapsedAsset);
    });

    // Step 3: Collapse assets in the same series (add _collapsedSeasonNumberEnd to the first season, and _hideBecauseCollapse to the rest of the seasons in the same series)
    const result = [];
    const sortedCollapsedAssets = collapsedAssets.sort((a, b) => a.metadata?.season?.seasonNumber - b.metadata?.season?.seasonNumber);
    sortedCollapsedAssets.forEach((asset) => {
        if (asset._hideBecauseCollapse) {
            return;
        }

        if (asset.metadata?.type?.toLowerCase() !== "episode") {
            result.push(asset);
            return;
        }

        if (asset._collapsedEpisodeNumberEnd < asset.metadata?.season?.numberOfEpisodes) {
            result.push(asset);
            return;
        }

        const assetsInSameSeries = sortedCollapsedAssets.filter(a => a.metadata?.series?.id === asset.metadata?.series?.id);
        const assetsInSameSeriesWithSeasonNumberHigherThanThisAsset = assetsInSameSeries.filter(a => a.metadata?.season?.seasonNumber > asset.metadata?.season?.seasonNumber);
        if (
            !assetsInSameSeriesWithSeasonNumberHigherThanThisAsset.length
            || assetsInSameSeriesWithSeasonNumberHigherThanThisAsset.some(seasonAsset => seasonAsset._collapsedEpisodeNumberEnd < seasonAsset.metadata?.season?.numberOfEpisodes)
        ) {
            result.push(asset);
            return;
        }

        const collapsedAsset = {
            ...asset,
            _collapsedSeasonNumberEnd: asset.metadata?.season?.seasonNumber,
        };

        for (let i = 0; i < assetsInSameSeriesWithSeasonNumberHigherThanThisAsset.length; i++) {
            const a = assetsInSameSeriesWithSeasonNumberHigherThanThisAsset[i];
            if (a.metadata?.season?.seasonNumber === collapsedAsset._collapsedSeasonNumberEnd + 1) {
                a._hideBecauseCollapse = true;
                collapsedAsset._collapsedSeasonNumberEnd = a.metadata?.season?.seasonNumber;
            } else {
                break;
            }
        }

        result.push(collapsedAsset);
    });

    return result;
}

function renderItemImage(image, renderPlus, renderKatsomoLogo) {
    let plus = null;
    if (renderPlus) {
        plus = (
            <div className="plus">PLUS</div>
        );
    }

    const katsomoLogo = renderKatsomoLogo && (
        <div className="channel-logo channel-mtv-katsomossa"></div>
    );

	if (image) {
        return (
            <div className="asset-image-container">
                {plus}
                {katsomoLogo}
                <ResponsiveImage id={image.id} sizes="(min-width: 985px) 25vw, (min-width: 665px) 50vw, 50vw" />
            </div>
        );
    }

    return (
        <div className="asset-image-container">
            {plus}
            {katsomoLogo}
            <div className="asset-image-placeholder" />
        </div>
    );
}

function getAssetTitle(asset) {
    if (asset.metadata?.type === "episode") {
        return asset.metadata?.series?.originalTitle + ": " + getEpisodeAssetSeasonInfo(asset);
    }

    return asset.metadata?.originalTitle ?? asset.name;
}

function getEpisodeAssetSeasonInfo(asset) {
    let seasonInfo = "";
    if (asset.metadata?.season?.seasonNumber) {
        seasonInfo += `Kausi ${asset.metadata.season.seasonNumber}`;
    }
    // If we have collapsed season in the same series, only show the end season number and skip the episode number
    if (asset._collapsedSeasonNumberEnd && asset.metadata?.season?.seasonNumber !== asset._collapsedSeasonNumberEnd) {
        seasonInfo += ` - ${asset._collapsedSeasonNumberEnd}`;
        return seasonInfo;
    }

    // If the collapsed season contains all the episodes in the season, skip the episode number
    if (asset.metadata?.season?.numberOfEpisodes && asset.metadata.season.numberOfEpisodes === asset._collapsedEpisodeNumberEnd) {
        return seasonInfo;
    }

    if (asset.metadata?.season?.seasonNumber && asset.metadata?.episodeNumber) {
        seasonInfo += ", ";
    }
    if (asset.metadata?.episodeNumber) {
        seasonInfo += `Jakso ${asset.metadata.episodeNumber}`;
    }
    if (asset._collapsedEpisodeNumberEnd && asset.metadata?.episodeNumber !== asset._collapsedEpisodeNumberEnd) {
        seasonInfo += ` - ${asset._collapsedEpisodeNumberEnd}`;
    }
    return seasonInfo;
}

function getChannelHeaderExtra(channel, day, togglePrimetimeOnlyForChannel, primetimeOnly) {
    const [icon, text] = primetimeOnly
        ? ["icon-unfold_more", "Näytä koko päivä"]
        : ["icon-unfold_less", "Piilota koko päivä"];
    return (
        <div
            className="channel-primetime-toggle"
            onClick={() => togglePrimetimeOnlyForChannel(channel, day)}
        >
            <span className={icon}>{text}</span>
        </div>
    );
}

function useSchedules(dates, channels) {
    const { data } = useQuery(
        ["c6-press-search", "schedules", dates, channels],
        () => {
            const promises = dates.map(date => {
                return PressAPI.fetchSchedules({ date, channels });
            });
            return Promise.all(promises);
        },
    );
    return data;
}

function useVodViews(dates) {
    const { data } = useQuery(
        ["c6-press-search", "vod-views", dates],
        () => {
            const promises = dates.map(date => {
                return PressAPI.fetchVodViews({ date, rightTypes: ["avod", "svod", "hvod"] });
            });
            return Promise.all(promises);
        },
    );
    return data;
}

const categoryOptions = [
    { key: null,                            text: "Kaikki" },
    { key: "news",                          text: "Uutis- ja ajankohtaisohjelmat" },
    { key: "movies",                        text: "Elokuvat" },
    { key: "domestic-entertainment",        text: "Kotimaiset viihdeohjelmat" },
    { key: "international-entertainment",   text: "Kansainväliset viihdeohjelmat" },
    { key: "domestic-drama",                text: "Kotimainen draama" },
    { key: "international-drama",           text: "Kansainvälinen draama" },
];

function isProgramCategory(metadata, category) {
    switch (category) {
        case "news":
            return metadata?.category === "News";
        case "movies":
            return metadata?.category === "Movie";
        case "domestic-entertainment":
            return metadata?.category === "Entertainment"
                && (metadata?.productionCountries?.includes("Finland") || !metadata.productionCountries?.length);
        case "international-entertainment":
            return metadata?.category === "Entertainment"
                && metadata?.productionCountries?.length
                && !metadata?.productionCountries?.includes("Finland");
        case "domestic-drama":
            return metadata?.category === "Scripted"
                && (metadata?.productionCountries?.includes("Finland") || !metadata.productionCountries?.length);
        case "international-drama":
            return metadata?.category === "Scripted"
                && metadata?.productionCountries?.length
                && !metadata?.productionCountries?.includes("Finland");
        default:
            return true;
    }
}


function copyScheduleForDay(day, channel, broadcasts, e) {
	const content = document.createElement("div");

	const channelNameEl = document.createElement("h1");
	channelNameEl.innerText = (channel.displayName ?? channel.name) + " - " + moment(day).format("DD.MM.YYYY");
	content.appendChild(channelNameEl);

	const fixedUtcOffset = moment().hours(4).isDST() ? 3 : 2; // DST ends 03:00
	broadcasts.forEach(broadcast => {
		const broadcastEl = document.createElement("div");
		content.appendChild(broadcastEl);
		content.appendChild(document.createElement("br"));

		const titleEl = document.createElement("div");
		titleEl.style.fontWeight = "bold";
		broadcastEl.appendChild(titleEl);
		const time = fixedUtcOffset
			? moment(broadcast.public.start).utcOffset(fixedUtcOffset).format("HH:mm")
			: moment(broadcast.public.start).format("HH:mm");
			titleEl.innerHTML = time + " " + broadcast.title;

		if (!broadcast.metadata || broadcast.metadata?.category === "News") {
			return;
		}

		if (broadcast.metadata.type === "Episode") {
			if (broadcast.metadata.seasonNumber) {
				titleEl.innerHTML += `, ${Translations.getTranslation("season").toLowerCase()} ${broadcast.metadata.seasonNumber}`;
			}

            broadcast.metadata.productionCountries = translateProductionCountries(broadcast.metadata.productionCountries);
			const facts = getFacts(broadcast.metadata, {});
			const numberOfEpisodes = broadcast.metadata.numberOfEpisodes > 0 ? `${broadcast.metadata.numberOfEpisodes} ${Translations.getTranslation("episodes").toLowerCase()}.` : null;
			if (facts || numberOfEpisodes) {
				const metadataEl = document.createElement("div");
				metadataEl.innerHTML = upperFirst(facts) + numberOfEpisodes;
				metadataEl.style.marginLeft = "41px";
				broadcastEl.appendChild(metadataEl);
			}

			const episodeTitleEl = document.createElement("div");
			if (broadcast.metadata.episodeNumber) {
                episodeTitleEl.innerText = `${Translations.getTranslation("episode")} ${broadcast.metadata.episodeNumber}`;
                if (broadcast.metadata.episodeTitle?.length) {
                    episodeTitleEl.innerText += ` - ${broadcast.metadata.episodeTitle}`;
                }
			}
			if (broadcast.metadata.episodeNumber && broadcast.metadata.episodeTitle) {
				episodeTitleEl.innerText += " - ";
			}
			if (broadcast.metadata.episodeTitle) {
				episodeTitleEl.innerText += broadcast.metadata.episodeTitle;
			}
			episodeTitleEl.style.marginLeft = "41px";
			broadcastEl.appendChild(episodeTitleEl);
		}

		const synopsis = broadcast.metadata.episodeSynopsis?.medium || broadcast.metadata.episodeSynopsis?.short;
		if (synopsis) {
			const synopsisEl = document.createElement("div");
			synopsisEl.innerHTML = synopsis;
			synopsisEl.style.marginLeft = "41px";
			broadcastEl.appendChild(synopsisEl);
		}
	});

	copyHtmlToClipboard(content.innerHTML, Translations.getTranslation("copyProgramInfoDone"), e);
}