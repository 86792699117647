import { addButtons } from './amp-custom-buttons-plugin'
import { addWatermark } from './watermark-plugin'
import * as StarAPI from '../../apis/star'
import appConfig from 'config'

let isAMPScriptPresent = false;
let pluginsLoaded = false;
let players = [];

export function onAMPReady(callback) {
	// Add AMP script if not already added
	if (!isAMPScriptPresent) {
		addAMPScript();
		isAMPScriptPresent = true;
	}

	// Wait for AMP to load
	const interval = setInterval(() => {
		if (isAMPLoaded()) {
			clearInterval(interval);
			callback();
		}
	}, 10);
}

export function waitForAMP() {
	return new Promise((resolve, reject) => {
		onAMPReady(() => {
			resolve();
		});
	});
}

export async function initPlayer({ playerRef, asset = {}, options }) {
	await waitForAMP();
	if (!pluginsLoaded) {
		await loadPlugins();
	}

	const { endCreditTimestamp, assetGuid } = asset;
	const { hideDL, hideEC, watermark } = options;
	const player = window.amp(playerRef, {
		autoplay: options.autoplay ?? false,
		controls: true,
		width: "100%",
		height: "100%",
		logo: { enabled: false },
		plugins: {
			"c6-custom-buttons": { endCreditTimestamp, assetGuid, hideDL, hideEC },
			"watermark": { watermark },
		},
	}, function() {
		this.addEventListener(window.amp.eventName.loadstart, () => {
			this.el_.classList.add("ready"); // The player will not receive mouse events until we add our "ready" class
		});
	});

	players.push(player);
	const playerIndex = players.length - 1;
	return playerIndex;
}

export async function disposePlayer(playerId) {
	await waitForAMP();
	const player = players[playerId];
	if (player?.dispose) {
		player.dispose();
	}
}

export async function setPlayerSources(playerId, sources) {
	await waitForAMP();
	const player = players[playerId];
	console.log("sources", sources);
	if (player && sources?.length) {
		player.ready(() => {
			player.src(sources);
		});
		// player.src([{
		// 	src: "https://amssamples.streaming.mediaservices.windows.net/830584f8-f0c8-4e41-968b-6538b9380aa5/TearsOfSteelTeaser.ism/manifest",
		// 	type: "application/vnd.ms-sstr+xml",
		// 	protectionInfo: [
		// 		{
		// 			"type": "AES",
		// 			"authenticationToken": "Bearer=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1cm46bWljcm9zb2Z0OmF6dXJlOm1lZGlhc2VydmljZXM6Y29udGVudGtleWlkZW50aWZpZXIiOiI5ZGRhMGJjYy01NmZiLTQxNDMtOWQzMi0zYWI5Y2M2ZWE4MGIiLCJpc3MiOiJodHRwOi8vdGVzdGFjcy5jb20vIiwiYXVkIjoidXJuOnRlc3QiLCJleHAiOjE3MTA4MDczODl9.lJXm5hmkp5ArRIAHqVJGefW2bcTzd91iZphoKDwa6w8"
		// 		}
		// 	]
		// }]);
	}
}

export async function loadPlugins() {
	await waitForAMP();
	addButtons();
	addWatermark();
	pluginsLoaded = true;
}

function addAMPScript() {
	// AMP JS
	const script = document.createElement("script");
	script.onerror = e => console.error("AMP script onerror", e);
	script.async = true;
	script.src = "client/azuremediaplayer.min.js";
	document.head.appendChild(script);

	// AMP CSS
	const link = document.createElement("link");
	link.rel = "stylesheet";
	// will also load /client/assets/fonts/azuremediaplayer.woff
	// will also load /client/skins/amp-default/assets/fonts/segoe/segoeui.woff
	link.href = "client/azuremediaplayer.min.css";
	document.head.appendChild(link);
}

function isAMPLoaded() {
	return window.amp !== undefined;
}

export async function downloadFile(assetGuid, fileId, convert) {
	const a = document.createElement("a");
	a.target = "_blank";
	const downloadLink = fileId
		? `${appConfig.api.star}assets/${assetGuid}/content`
		: await StarAPI.fetchAssetDownloadLink(assetGuid);
	const qParams = new URLSearchParams();
	if (fileId) {
		qParams.set("fileId", fileId);
		qParams.set("useContainerName", true); // Human-readable file name
	}
	if (convert) {
		qParams.set("convert", convert);
	}
	a.href = `${downloadLink}?${qParams.toString()}`;
	a.download = assetGuid;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export async function downloadVideo(assetGuid) {
	await downloadFile(assetGuid);
}