import { browserHistory } from 'browserHistory'
import moment from 'moment'

import alt from '../../core/services/alt'
import * as APIS from '../../apis/'
import * as Alert from '../../core/services/alert'

import { getConstrainedDate } from './shared/utils'
import Translations from './shared/translations'

import Const from '../../core/constants'
import appConfig from 'config'

const API = "press"

class Actions {

	search(searchText, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				searchText,
				targetStore,
			});
		}
	}

	filter(filterValue, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
				targetStore,
			});
		};
	}

    /* **************** Items **************** */
    fetchItems(entity, payload, options = {}) {

        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const command = getCommand("fetch", entity, multiple);
            const store = targetStore || getStore(entity, multiple);

            dispatch({ payload, store, requestTime });

            APIS[api][command](payload)
                .then(response => {
					// C60
                    const { pageIndex, numberOfItems, items, links } = response;
					const nextPageUrl = getNextPageUrl(links);

					// C70
                    // const { result: items, meta } = response;
					// const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if (callback) {
                        callback(items);
                    }

                }, this.requestFailed);

        };
    }
    pageItems(entity, url, options = {}) {
        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const store = targetStore || getStore(entity, multiple);

            dispatch({ entity, url, store });

            APIS[api].fetchUrl(url)
                .then(response => {
					// C60
                    const { pageIndex, numberOfItems, items, links } = response;
					const nextPageUrl = getNextPageUrl(links);

					// C70
					// const { result: items, meta } = response;
					// const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if (callback) {
                        callback(items);
                    }

                }, this.requestFailed);
        };
    }
    itemsUpdated(payload) {
        return payload;
    }

    /* **************** Item **************** */
	fetchItem(entity, payload, options = {}) {
		return (dispatch) => {
			const {
                targetStore = entity,
                callback = null,
                api = API,
			} = options;

			dispatch(targetStore);

			const command = getCommand("fetch", entity);
			APIS[api][command](payload)
				.then(model => {
					this.itemLoaded({ entity: targetStore, model });
					if (callback) {
						callback(model);
					}
				}, this.requestFailed);
		};
    }

    itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			APIS[API][command](data, payload)
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		}
    }
    itemUpdated(payload) { return payload; }

    removeItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch({ entity, id: data.id, targetStore });

            const command = getCommand("delete", entity);
			APIS[API][command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id, targetStore });
				}, (error/*, request*/) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id, targetStore });
                    // this.requestFailed({ error, request });
                    this.requestFailed(error);
				});
		};
    }

    itemRemoved(payload) { return payload; }

    rollbackRemoveItem(payload) { return payload}

    /* ================ */
	/*      GENERAL     */
	/* ================ */
    unmount() { return true }

    persist(model, store, prepend) {
        return { store, model, prepend };
	}

	requestFailed(error) {
		Alert.displayAlert("error", error.exceptionMessage);
		return true;
	}

	// CUSTOM
	fetchSchedules(payload = {}) {
		return dispatch => {
			dispatch(payload);

			APIS[API].fetchSchedules(payload)
				.then(response => {
					this.itemsUpdated({
						store: "schedules",
						items: response,
						appendToExistingItems: false,
						numberOfItems: response.length,
						nextPageUrl: null,
						requestTime: null,
					});
				});
		}
	}

	fetchProgramReviews(program) {
		const enableProgramReviews = appConfig.features.pressEnableProgramReviews;
		const imdbId = program?.imdb ?? program?.series?.imdb;
		if (enableProgramReviews && imdbId) {
			this.fetchItem(
				"programReviews",
				{ id: imdbId },
				{ targetStore: "programReviews" },
			);
		}
	}

	fetchChannelGroups() {
		return dispatch => {
			dispatch();

			APIS[API].fetchChannelGroups()
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated({
						store: "channelGroups",
						items,
						appendToExistingItems,
						numberOfItems,
						nextPageUrl,
					});
				}, error => {
					// error.status 404 means the endpoint is not available, so we use the fallback list
					if (error.status === 404) {
						this.useChannelGroupsFallback();
					} else {
						this.requestFailed(error);
					}
				});
		}
	}

	useChannelGroupsFallback() { return true }

	// fetchWidget(widgetName) {
	// 	this.fetchItem(`widget`, { widgetName }, { targetStore: `widget-${widgetName}` });
	// 	return dispatch => {
			
	// 	};
	// }

	previewAsset(asset) { return asset }

	// Discovery
	discoverySearch(filters, searchText) {
		return (dispatch) => {
            dispatch(searchText);

			// const { filter } = filters;
            const searchFilters = getDiscoveryFilters(searchText);

			this.fetchItems("discovery", searchFilters, { targetStore: "discovery" });
		};
	}

	// UI ACTIONS
	navNext(date) {
		return (dispatch) => {
			const newDate = getConstrainedDate(moment(date).add(1, "day")).format(Const.DATE_FORMAT);
			dispatch(newDate);
			browserHistory.push(`/${Translations.getTranslation("url_schedules")}/${newDate}`);
		};
	}

	navPrev(date) {
		return (dispatch) => {
			const newDate = getConstrainedDate(moment(date).subtract(1, "day")).format(Const.DATE_FORMAT);
			dispatch(newDate);
			browserHistory.push(`/${Translations.getTranslation("url_schedules")}/${newDate}`);
		};
	}

	navToday() {
		return (dispatch) => {
			const newDate = moment().format(Const.DATE_FORMAT);
			dispatch(newDate);
			browserHistory.push(`/${Translations.getTranslation("url_schedules")}/${newDate}`);
		};
	}

	navDate(date) {
		return (dispatch) => {
			const navDate = getConstrainedDate(moment(date)).format(Const.DATE_FORMAT);
			dispatch(navDate);
			browserHistory.push(`/${Translations.getTranslation("url_schedules")}/${navDate}`);
		};
	}

	scheduleFilter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
			});
		};
	}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if (typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if (entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if (typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}

function getDiscoveryFilters(searchText) {
	return {
		// searchText,
		// validFromStart: "1900-01-01",
		// validFromEnd: moment().add(50, "days").format("YYYY-MM-DD"),
		// validUntilStart: moment().format("YYYY-MM-DD"),
		// validUntilEnd: "2100-12-31",
		// type: "SingleProgram,SeriesProgram"

		searchText,
		platform: appConfig.features.discoveryPlatform,
		type: "single,series",
		pageSize: 20,
	}
}