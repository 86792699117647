import React from 'react'
import moment from 'moment-timezone'

import { useLocalStorage } from '../../core/hooks/useStorage'
import useContentVodPackages from '../../core/queries/content/useContentVodPackages'

import appConfig from 'config'

export function getProgramLink(seriesId, seasonId, id) {
	const episodePart = id ? `/episode/${id}` : "";
	if (seriesId && seasonId) {
		return `/series/${seriesId}/season/${seasonId}${episodePart}`;
	}
	else if (seriesId) {
		return `/series/${seriesId}`;
	}
	else if (seasonId) {
		return `/season/${seasonId}${episodePart}`;
	}
	return `/program/${id}`;
}

export function getProgramFamilyLink(familyId, seasonId, id) {
	if (!seasonId) {
		return `/family/${familyId}/program/${id}`;
	}

	return id
		? `/family/${familyId}/${seasonId}/${id}`
		: `/family/${familyId}/${seasonId}`;
}

export function getProgramMetadata(id, metadata) {
	return metadata.find(m => m.id === id);
}

export function getSwedishVersion(versions = []) {
	return versions.find(v => v.language === "sv") || {};
}

// export function getPlatform(program) {
// 	// const servicesWithSVODRights = program.rights.filter(r => r.type.toLowerCase() === "svod" && moment().isBetween(r.validFrom, r.validUntil)).map(r => `${r.contentOwner}.${r.country}`);
// 	const activeSvodRights = getActiveSvodRights(program);
// 	if (activeSvodRights === null || !activeSvodRights || activeSvodRights.length === 0) return;

// 	const tv4SvodRight = activeSvodRights.find(r => r.contentOwner.toLowerCase() === 'tv4');
// 	const tv4SvodRightServiceUrl = getProgramServiceUrl(tv4SvodRight?.contentOwner.toLowerCase(), tv4SvodRight?.country.toLowerCase());
// 	const cmoreSvodRight = activeSvodRights.find(r => r.contentOwner.toLowerCase() === 'cmore');
// 	const cmoreSvodRightServiceUrl = getProgramServiceUrl(cmoreSvodRight?.contentOwner.toLowerCase(), cmoreSvodRight?.country.toLowerCase());

// 	return (
// 		<div className="season-single-platforms">
// 			<span className={`channel-logo channel-${tv4SvodRightServiceUrl?.channelClass}`}></span>
// 			<span className={`channel-logo channel-${cmoreSvodRightServiceUrl?.channelClass}`}></span>
// 		</div>
// 	);
// }

export function getProgramUpcomingBroadcasts(programBroadcasts, channels, program, seasonBroadcasts) {	
	const broadcasts = getProgramBroadcasts(seasonBroadcasts, program.id, programBroadcasts);
	const relevantBroadcasts = broadcasts?.filter((broadcast) => {
		return channels.some(c => c.key === broadcast.channel.name && c.group !== "hidden-virtual")
			&& moment(broadcast.publicStart).isAfter(moment());
	});
	if (!relevantBroadcasts?.length) {
		return;
	}

	const canExpand = relevantBroadcasts.length > 2;
	const inputId = `program-broadcasts-toggle-${program.id}`;
	return (
		<div className={`program-upcoming-broadcasts ${canExpand ? "can-expand" : ""}`}>
			<input id={inputId} type="checkbox" disabled={!canExpand}/>
			<h3>Kommande sändningar</h3>
			<table>
				<tbody>
					{relevantBroadcasts.map(({ publicStart, channel }, i) => (
						<tr className="program-upcoming-broadcasts-row" key={i}>
							<td>{MapProgramExposureDate(publicStart)}</td>
							<td><span className={`channel-logo channel-${channel.name}`}></span></td>
						</tr>
					))}
				</tbody>
			</table>
			{canExpand && <label htmlFor={inputId} className="icon-" data-showmore="Visa mer" data-showfewer="Visa mindre"></label>}
		</div>
	);
}

function getProgramBroadcasts(seasonBroadcasts, programId, programBroadcasts) {
	const broadcasts = seasonBroadcasts?.filter(br => br.programReference === programId);
	if (broadcasts?.length) {
		return broadcasts;
	}

	return programBroadcasts ?? [];
}

export function getSeasonPremieres(seasonBroadcasts) {
	const [expanded, setExpanded] = useLocalStorage("c6-weekly-premieres-expanded", false);

	// Step 1: Create a map with a list for each week containing the numbers for the days on which there are premieres
	// Example: { "2022-45": [0, 1, 3] } = week 45 has premieres on Monday, Tuesday & Thursday
	const weekPremiereNumberMap = {};
	const firstRunBroadcasts = (seasonBroadcasts ?? []).filter(br => br.status === "firstRun");
	firstRunBroadcasts.forEach(broadcast => {
		const when = moment(broadcast.publicStart);
		const year = when.year();
		const week = when.week();
		const day = when.weekday();
		const key = `${year}-${week}`; // Use year in key for correct sorting (week 52 before week 1)
		if (!weekPremiereNumberMap[key]) {
			weekPremiereNumberMap[key] = [];
		}
		if (!weekPremiereNumberMap[key].includes(day)) {
			weekPremiereNumberMap[key].push(day);
		}
	});

	// Step 2: Convert list with numbers to a string
	// Example: [0] becomes "Måndagar"
	// Example: [0, 1, 3] becomes "Mån, Tis, Tors"
	// Example: [0, 1, 2, 3] becomes "Mån-Tors"
	// Example: [0, 1, 2, 3, 6] becomes "Mån-Tors, Sön"
	// Example: [0, 1, 2, 3, 4, 5, 6] becomes "Varje dag"
	const weekNumberPremieres = {};
	Object.entries(weekPremiereNumberMap).slice(0, 10).forEach(([key, dayNumbers]) => {
		if (dayNumbers.length === 1) {
			weekNumberPremieres[key] = getDayStr(dayNumbers[0], true);
			return;
		}

		if (dayNumbers.length === 7) {
			weekPremiereNumberMap[key] = "Varje dag";
			return;
		}

		let str = getDayStr(dayNumbers[0]);
		for (let i = 1; i < dayNumbers.length; i++) {
			const dayNumber = dayNumbers[i];
			const dayStr = getDayStr(dayNumber, false);

			if (i !== dayNumbers.length - 1 && dayNumbers[i - 1] === dayNumber - 1 && dayNumbers[i + 1] === dayNumber + 1) {
				str += "-";
			} else {
				str += "," + dayStr;
			}
		}
		str = str.replace(/-+,/gi, "-");
		str = str.replace(/,/gi, ", ");
		weekNumberPremieres[key] = str;
	});

	if (Object.entries(weekNumberPremieres).length === 0) {
		return null;
	}

	const canExpand = Object.entries(weekNumberPremieres).length > 4;

	return (
		<div className={`program-upcoming-broadcasts program-weekly-premieres ${canExpand ? "can-expand" : ""}`}>
			<input
				id="program-weekly-premieres-toggle"
				type="checkbox"
				disabled={!canExpand}
				checked={expanded}
				onChange={() => {
					setExpanded(!expanded);
				}}
			/>
			<h3>Nya avsnitt</h3>
			<table>
				<tbody>
					{Object.entries(weekNumberPremieres).map(([key, premieres]) => {
						const [_, week] = key.split("-");
						return (
							<tr key={key}>
								<td>V{week}</td>
								<td>{premieres}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			{canExpand && <label htmlFor="program-weekly-premieres-toggle" className="icon-" data-showmore="Visa mer" data-showfewer="Visa mindre"></label>}
		</div>
	);
}

function getDayStr(dayNumber, long) {
	return long
		? ["Måndagar", "Tisdagar", "Onsdagar", "Torsdagar", "Fredagar", "Lördagar", "Söndagar"][dayNumber]
		: ["Mån", "Tis", "Ons", "Tors", "Fre", "Lör", "Sön"][dayNumber]
}

export function Availability({ program, programNid, platform, heading, skipLinkIfNoProgramId }) {
	const { data: vodPackages } = useContentVodPackages({
		filter: { programId: program?.id },
		enabled: !!program?.id,
	});
	const activeSvodPublishingPeriods = vodPackages?.data?.length ? getActivePublishingPeriods(vodPackages.data, "svod", platform) : [];
	const activeAvodPublishingPeriods = vodPackages?.data?.length ? getActivePublishingPeriods(vodPackages.data, "avod", platform) : [];
	const activeHvodPublishingPeriods = vodPackages?.data?.length ? getActivePublishingPeriods(vodPackages.data, "hvod", platform) : [];
	if (!activeSvodPublishingPeriods?.length && !activeAvodPublishingPeriods?.length && !activeHvodPublishingPeriods?.length) {
		return null;
	}

	return (
		<div className="program-service-availability">
			<h3>{heading ?? "VOD-tillgänglighet"}</h3>
			<table>
				<tbody>
					{renderPublishingPeriods(activeSvodPublishingPeriods, "SVOD", programNid, skipLinkIfNoProgramId)}
					{renderPublishingPeriods(activeAvodPublishingPeriods, "AVOD", programNid, skipLinkIfNoProgramId)}
					{renderPublishingPeriods(activeHvodPublishingPeriods, "HVOD", programNid, skipLinkIfNoProgramId)}
				</tbody>
			</table>
		</div>
	);
}

export function FutureAvailability({ program, programNid, platform, heading, skipLinkIfNoProgramId }) {
	const { data: vodPackages } = useContentVodPackages({
		filter: { programId: program?.id },
		enabled: !!program?.id,
	});
	const futureSvodPublishingPeriods = vodPackages?.data?.length ? getFuturePublishingPeriods(vodPackages.data, "svod", platform) : [];
	const futureAvodPublishingPeriods = vodPackages?.data?.length ? getFuturePublishingPeriods(vodPackages.data, "avod", platform) : [];
	const futureHvodPublishingPeriods = vodPackages?.data?.length ? getFuturePublishingPeriods(vodPackages.data, "hvod", platform) : [];
	if (!futureSvodPublishingPeriods?.length && !futureAvodPublishingPeriods?.length && !futureHvodPublishingPeriods?.length) {
		return null;
	}

	return (
		<div className="program-future-availability">
			<h3>{heading ?? "Kommande VOD-tillgänglighet"}</h3>
			<table>
				<tbody>
					{renderPublishingPeriods(futureSvodPublishingPeriods, "SVOD", programNid, skipLinkIfNoProgramId)}
					{renderPublishingPeriods(futureAvodPublishingPeriods, "AVOD", programNid, skipLinkIfNoProgramId)}
					{renderPublishingPeriods(futureHvodPublishingPeriods, "HVOD", programNid, skipLinkIfNoProgramId)}
				</tbody>
			</table>
		</div>
	);
}

function renderPublishingPeriods(publishingPeriods, type, programNid, skipLinkIfNoProgramId) {
	return publishingPeriods?.map(({ platform, start, end }, i) => {
		const { href, text, channelClass } = getProgramServiceUrl(null, null, programNid, platform);
		return (
			<tr key={i}>
				<td><span className={`channel-logo channel-${channelClass}`}></span></td>
				{skipLinkIfNoProgramId && !programNid && (<td><span className="pp-title">{text} <span>({type})</span></span></td>)}
				{(!skipLinkIfNoProgramId || programNid) && (<td><span className="pp-title"><a className="c6-link" href={href}>{text} <span>({type})</span></a></span></td>)}
				<td><span> {moment(start).format("YYYY-MM-DD")} - {moment(end).format("YYYY-MM-DD")}</span></td>
			</tr>
		);
	});
}

export function getActivePublishingPeriods(vodPackages, type, platform) {
	const publishingPeriods = vodPackages?.reduce((periods, vp) => {
		return [...periods, ...(vp.publishingPeriods ?? [])];
	}, []);
	if (publishingPeriods?.length) {
		return publishingPeriods.filter(p => (
			(p.type?.toLowerCase() === type && moment().isBetween(p.start, p.end))
			&& (!platform || p.platform?.toLowerCase() === platform.toLowerCase())
		));
	}

	return null;
}

export function getFuturePublishingPeriods(vodPackages, type, platform) {
	const publishingPeriods = vodPackages?.reduce((periods, vp) => {
		return [...periods, ...(vp.publishingPeriods ?? [])];
	}, []);
	if (publishingPeriods?.length) {
		return publishingPeriods.filter(p => (
			(p.type?.toLowerCase() === type && moment().isBefore(p.start))
			&& (!platform || p.platform?.toLowerCase() === platform.toLowerCase())
		));
	}

	return null;
}

export function getSynopsis(program, reverseOrder = false, language = "sv", texts = {}, hideExtendedForSeasons = true) {
	let versions;
	if (language === null || !program.versions && program.synopsis) {
		versions = [{ synopsis: program.synopsis, language: language || "default" }];
	} else {
		versions = (program.versions || []).filter(v => v.language === language);
	}

	return versions.length
		? versions.map(v => {
			let { extended, long, medium, brief, short, press } = v.synopsis;
			if (appConfig.features.pressRenderPressSynopsis && press?.length) {
				return <div key={v.language} className="press-synopsis-wrapper"><div dangerouslySetInnerHTML={{ __html: press }}></div></div>;
			}
			extended = program.type === "season" && hideExtendedForSeasons ? null : extended;
			const programDescriptionMissingText = texts["programDescriptionMissing"] || "Programbeskrivning saknas.";
			return reverseOrder
				? <p key={v.language}>{short || brief || medium || long || extended || programDescriptionMissingText !== " " && <em>{programDescriptionMissingText}</em>}</p>
				: <p key={v.language}>{extended || long || medium || brief || short || programDescriptionMissingText !== " " && <em>{programDescriptionMissingText}</em>}</p>;
		})
		: null;
}

export function getSeriesMetadata(metadata, content) {
	if (!metadata || metadata.type === "single" || metadata.type === "series") {
		return null;
	}

	const seasonMetadata = getProgramMetadata(metadata.episodeInfo.seasonId || metadata.seasonId, content);
	return getProgramMetadata(seasonMetadata.seasonInfo.seriesId || seasonMetadata.seriesId, content);
}

export function renderLocalRating(program) {
	if (!program || !program.localRating || program.localRating.type === "noRating")
		return null;
	
	const { age, symbols } = program.localRating;

	return (
		<div className="local-rating">
			{age && age !== "noRating" && <img src={`/client/img/${age}.png`} alt={age} title={age} />}
			{symbols.map(s => <img key={s} src={SYMBOL_IMAGE_MAP[s]} alt={s} title={s} />)}
		</div>
	);
}

function getExposureTextAndLink(owner, exposureType) {
	if (exposureType === "svod" || exposureType === "hvod") {
		return {
			href: "https://mtv.fi",
			text: "MTV Katsomo+",
			channelClass: OWNER_CHANNEL_CLASSNAME_MAP["mtv"],
		};
	}

	if (exposureType === "avod") {
		return {
			href: "https://mtv.fi",
			text: "MTV Katsomosta",
			channelClass: OWNER_CHANNEL_CLASSNAME_MAP["mtv"],
		};
	}

	if (owner === "svt") {
		return {
			href: "https://svtplay.se",
			text: "svtplay.se",
			channelClass: OWNER_CHANNEL_CLASSNAME_MAP[owner],
		};
	}

	return {};
}

function getProgramServiceUrl(owner, country, programNid, platform) {
	if (owner === "cmore" || owner === "britbox") {
		return { href: `https://cmore.${country}`, text: `cmore.${country}`, channelClass: OWNER_CHANNEL_CLASSNAME_MAP["cmore"] };
	}

	if (owner === "tv4" || platform === "tv4play") {
		return {
			href: programNid ? `https://www.tv4play.se/program/${programNid}` : "https://www.tv4play.se",
			text: "tv4play.se",
			channelClass: OWNER_CHANNEL_CLASSNAME_MAP["tv4"],
		};
	}

	if (platform === "mtvkatsomo") {
		return {
			href: "https://www.mtv.fi",
			text: "MTV Katsomo+",
			channelClass: OWNER_CHANNEL_CLASSNAME_MAP["mtv"],
		};
	}

	return {};
}

export function renderExposures(program, texts = {}) {
	const now = moment();
	const exposures = (program.exposures || []).filter(exposure => moment(exposure.until).isAfter(now));
	const svodOrHvodExposure = exposures.find(e => e.type === "svod" || e.type === "hvod");
	const avodExposure = exposures.find(e => e.type === "avod");
	
	if (exposures.length) {
		return (
			<div className="program-exposures">
				<h2>{texts["availability"] || "Availability"}</h2>
				{svodOrHvodExposure && <Exposure exposure={svodOrHvodExposure} program={program} texts={texts} />}
				{avodExposure && <Exposure exposure={avodExposure} program={program} texts={texts} />}
			</div>
		);
	}
	
	return null;
}

function MapProgramExposureDate(broadcastDate) {
	const date = moment(broadcastDate);
	const isSameAsCurrentYear = moment(broadcastDate).isSame(new Date(), 'year');

	// If same year as today don't present the (YYYY) year
	if (isSameAsCurrentYear) {
		return date.locale("se").format("D/M dddd LT");
	}
	return date.locale("se").format("D/M dddd LT (YYYY)");
}

function Exposure({ exposure, program, texts }) {
	const now = moment();
	const isActive = false;//moment(exposure.from).isBefore(now);
	const owner = exposure.owner.toLowerCase();
	const { href, text, channelClass } = getExposureTextAndLink(owner, exposure.type);

	const deepLink = useDeepLink(program);

	if (deepLink || href) {
		return (
			<div className="exposure">
				<span className={`channel-logo channel-${channelClass}`}></span>
				<span>{texts["availableOn"] || "Available on"}</span>&nbsp;
				{isActive && href && <a href={deepLink || href} target="_blank">{text}</a>}
				{isActive && !href && <span>{text}</span>}
				{!isActive && <span>{text}</span>}
				&nbsp;
				<span>{isActive ? null : moment(exposure.from).tz(appConfig.features.pressTimezone).format("D/M")}</span>
			</div>
		);
	}
	
	return null;
}

export function renderBroadcasts(program, programSchedules, texts = {}, timezone, excludeFirstrun, maxBroadcasts, logoFirst = false, onlyIncludeBroadcastsWithinDays = null) {
	let programBroadcasts = programSchedules.programId === program.id
		? programSchedules.broadcasts
		: ((programSchedules.episodes || []).find(e => e.programId === program.id) || {}).broadcasts;
	if (excludeFirstrun) {
		programBroadcasts = programBroadcasts && programBroadcasts.filter(br => br.status !== "firstRun");
	}
	if (onlyIncludeBroadcastsWithinDays > 0) {
		const maxDate = moment().add(onlyIncludeBroadcastsWithinDays, "days");
		programBroadcasts = programBroadcasts && programBroadcasts.filter(br => moment(br.public.start).isBefore(maxDate));
	}
	if (maxBroadcasts) {
		programBroadcasts = programBroadcasts && programBroadcasts.slice(0, maxBroadcasts);
	}
	
	const canExpand = programBroadcasts && programBroadcasts.length > 2;
	const inputId = `program-broadcasts-toggle-${program.id}`;
	if (programBroadcasts && programBroadcasts.length > 0) {
		return (
			<div className={`program-broadcasts ${canExpand ? "can-expand" : ""}`}>
				<input id={inputId} type="checkbox" disabled={!canExpand} />
				<h2>{texts["upcomingBroadcasts"] || "Kommande sändningar"}</h2>
				<table>
					<tbody>
						{programBroadcasts.map(({ channel, public: publicTimestamps }, i) => {
							const logo = (<td><span className={`channel-logo channel-${channel.name}`}></span></td>);
							return (
								<tr key={i}>
									{logoFirst && logo}
									<td>{getBroadcastText(publicTimestamps.start, texts, timezone)}</td>
									{!logoFirst && logo}
								</tr>
							);
						})}
					</tbody>
				</table>
				{/* HACK: Use icon- to get the icomoon style. The specific icon used will be controlled by CSS */}
				{canExpand && <label htmlFor={inputId} className="icon-" data-showmore={texts["showMore"] || "Visa fler"} data-showfewer={texts["showFewer"] || "Visa färre"}></label>}
			</div>
		);
	}
	
	return null;
}

export function renderNextUpcomingBroadcast(program, upcomingBroadcasts, texts = {}, timezone, logoFirst = false) {
	if (
		program && program.id !== upcomingBroadcasts.programId
		|| !upcomingBroadcasts.nextUpcomingBroadcast
	) {
		return null;
	}

	const broadcast = upcomingBroadcasts.nextUpcomingBroadcast;
	const logo = (<td><span className={`channel-logo channel-${broadcast.channel.name}`}></span></td>);
	return (
		<div className="program-broadcasts">
			<h2>{texts["nextBroadcast"] || "Nästa sändning"}</h2>
			<table>
				<tbody>
					<tr>
						{logoFirst && logo}
						<td>{getBroadcastText(broadcast.public.start, texts, timezone)}</td>
						{!logoFirst && logo}
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export function getUpcomingEpisode(episodes = []) {
	const now = moment();

	// Get the closest upcoming episode exposure and use it to set episode.activeFrom & episode.activeUntil
	const episodesWithDates = episodes.map(e => {
		const upcomingExposures = (e.exposures || []).filter(exp => exp.type === "linear" && moment(exp.from).isAfter(now));
		const nextUpcomingExposure = upcomingExposures.sort((a, b) => moment(a.from).unix() - moment(b.from).unix()).pop();
		return {
			...e,
			activeFrom: nextUpcomingExposure ? nextUpcomingExposure.from : null,
			activeUntil: nextUpcomingExposure ? nextUpcomingExposure.until : null,
		};
	});

	// Take the next upcoming episode, if there are any upcoming episodes. Otherwise, take the first episode in the list
	const upcomingEpisodes = episodesWithDates.filter(e => moment(e.activeFrom).isAfter(now));
	const nextUpcomingEpisode = upcomingEpisodes.sort((a, b) => moment(a.activeFrom).unix() - moment(b.activeFrom).unix()).shift();
	return nextUpcomingEpisode || episodes[0];
}

export function renderEmptySeries(series) {
	return (
		<div style={{ display: "block", margin: "0 auto", padding: "10px", backgroundColor: "var(--content-bg-color)", textAlign: "center" }}>
			No active seasons for {series.title}
		</div>
	);
}

export function getUpcomingBroadcasts(programSchedules, type) {
	const now = moment();
	if (type === "season") {
		let nextUpcomingBroadcastInSeason = null;
		const episodesWithOnlyUpcomingBroadcasts = (programSchedules.episodes || []).map(ep => ({
			...ep,
			broadcasts: ep.broadcasts.filter(br => moment(br.public.start).isAfter(now)),
		}));
		episodesWithOnlyUpcomingBroadcasts.forEach(({ broadcasts }) => {
			let nextUpcomingBroadcastForEpisode = null;
			const upcomingLiveBroadcasts = broadcasts.filter(br => br.status === "firstRun");

			// Prioritize live or firstRun broadcasts
			if (upcomingLiveBroadcasts.length && (!nextUpcomingBroadcastForEpisode || isBeforeBroadcast(upcomingLiveBroadcasts[0], nextUpcomingBroadcastInSeason))) {
				nextUpcomingBroadcastForEpisode = upcomingLiveBroadcasts[0];
			}
			// If there were no live or firstRun broadcasts, take any type of upcoming broadcast
			else if (!upcomingLiveBroadcasts.length && broadcasts.length && (!nextUpcomingBroadcastForEpisode || isBeforeBroadcast(broadcasts[0], nextUpcomingBroadcastForEpisode))) {
				nextUpcomingBroadcastForEpisode = broadcasts[0];
			}

			if (
				nextUpcomingBroadcastForEpisode
				&& (
					!nextUpcomingBroadcastInSeason
					|| nextUpcomingBroadcastInSeason.status !== "firstRun" && nextUpcomingBroadcastForEpisode.status === "firstRun"
					|| nextUpcomingBroadcastInSeason.status === "firstRun" && nextUpcomingBroadcastForEpisode.status === "firstRun" && isBeforeBroadcast(nextUpcomingBroadcastForEpisode, nextUpcomingBroadcastInSeason)
					|| nextUpcomingBroadcastInSeason.status !== "firstRun" && nextUpcomingBroadcastForEpisode.status !== "firstRun" && isBeforeBroadcast(nextUpcomingBroadcastForEpisode, nextUpcomingBroadcastInSeason)
				)
			) {
				nextUpcomingBroadcastInSeason = nextUpcomingBroadcastForEpisode;
			}
		});

		return {
			...programSchedules,
			nextUpcomingBroadcast: nextUpcomingBroadcastInSeason,
			episodes: episodesWithOnlyUpcomingBroadcasts,
		};
	}

	// Single program
	const upcomingBroadcasts = (programSchedules.broadcasts || []).filter(br => moment(br.public.start).isAfter(now));
	return {
		...programSchedules,
		broadcasts: upcomingBroadcasts,
	};
}


// HELPERS
export function getBroadcastText(time, texts, timezone) {
	const format = `DD/M dddd [${texts["at"] ?? ""}] HH:mm`;
	return timezone
		? moment(time).tz(timezone).format(format)
		: moment(time).format(format);
}

function isBeforeBroadcast(br1, br2) {
	return moment(br1.public.start).isBefore(moment(br2.public.start));
}

function useDeepLink(program) {
	if (program?.type === "episode" && program?.id) {
		return `https://l.mtv.fi/ext-episode/${program.id}`;
	}

	if (program?.id) {
		return `https://l.mtv.fi/ext-program/${program.id}`;
	}
}

const OWNER_CHANNEL_CLASSNAME_MAP = {
	"mtv": "mtv-katsomossa",
	"mtvfi": "mtv-katsomossa",
	"svt": "svt-play",
	"cmore": "cmore",
	"tv4": "tv4play",
};
const SYMBOL_IMAGE_MAP = {
	"Väkivalta": "/client/img/V.png",
	"Seksi": "/client/img/X.png",
	"Ahdistus": "/client/img/A.png",
	"Päihteet": "/client/img/P.png",
};