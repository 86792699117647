import React from 'react'
import PropTypes from 'prop-types'

import * as BMPutils from './utils'
import Spinner from '../spinner'

import appConfig from 'config'

import './bmp.css'

export default class BitmovinPlayer extends React.Component {
	playerIndex = null;
	playerRef = React.createRef();
	isInitialized = false;
	state = {
		playerLoaded: false,
	};

	componentDidMount() {
		this.handleSources(this.props.sources);
	}

	componentDidUpdate() {
		this.handleSources(this.props.sources);
	}

	componentWillUnmount() {
		BMPutils.destroyPlayer(this.playerIndex);
	}

	handleSources = (sources) => {
		if (this.isInitialized) {
			return;
		}
		
		const source = sources?.[0];
		if (source?.src && source?.isManifestFile) {
			this.initSingu(source.src);
			this.isInitialized = true;
		} else if (source?.src) {
			this.init({
				progressive: [{
					url: source.src,
					type: source.type,
				}],
			});
			this.isInitialized = true;
		}
	}

	initSingu = (manifestUrl) => {
		// Input parsing
        const manifestURLParsed = new URL(manifestUrl);

		let analytics = {};
		{/* eslint-disable-next-line no-undef */}
		if (__BUILD_PRODUCT__ === "press") {
			analytics = {
				key: "b793edd5-e96b-404d-9f4c-8e7b8e3668fe",
				title: "MTVPressi player",
				videoId: manifestURLParsed.searchParams.get("asset") ?? "missing",
			};
		}

        const licenseServer = "https://origin.prod.singu.fi";
        const playKey = manifestURLParsed.searchParams.get("play_key")
        const source = {
            dash: manifestUrl,
            drm: {
                // DRM configuration, that uses our license proxy
                playready: {
                    LA_URL: `${licenseServer}/drm/playready?key=${playKey}`
                },
                widevine: {
                    LA_URL: `${licenseServer}/drm/widevine?key=${playKey}`
                },
            },
        };

		this.init(source, analytics);
	}

	init = async (source, analytics = {}) => {
		const playerConf = {
			key: appConfig.features.bitmovinPlayerKey,
			ui: false,
            playback: {
                autoplay: this.props.autoplay
            },
			analytics,
		};

		this.playerIndex = await BMPutils.initPlayer({
			playerRef: this.playerRef.current,
			config: playerConf,
			source: source,
			asset: this.props.asset,
			enableDownload: this.props.enableDownload,
			enableEndcreditJump: this.props.enableEndcreditJump,
		});

		this.setState({ playerLoaded: true });
	}

	render() {
		return (
			<div className={`c6-bmp ${this.props.sources?.length ? "has-sources" : ""}`}>
				<div ref={this.playerRef}></div>
				{this.props.displaySpinnerWhenLoading && (
					<Spinner loading={!this.state.playerLoaded} />
				)}
			</div>
		);
	}
}


BitmovinPlayer.propTypes = {
	asset: PropTypes.shape({
		assetGuid: PropTypes.string,
	}),
	sources: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string,
		type: PropTypes.string,
	})),
	enableDownload: PropTypes.bool,
	enableEndcreditJump: PropTypes.bool,
	autoplay: PropTypes.bool,
	watermark: PropTypes.string,
};
