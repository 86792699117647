import { useState } from 'react'
import Widget from './widget'
import ResponsiveImage from '../../../components/assets/responsiveImage'

import './tabbedItems.css'


const TabbedItems = (props) => {
	const { widgetData } = props;
	const { items } = widgetData;

	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const activeItem = items?.[activeTabIndex];
	return (
		<div className="c6-press-tabbed-items">
			<div className="tabs">
				{items?.map((item, index) => {
					const imageAssetId = item.assets?.find(a => a.type === "image")?.id;
					return (
						<div
							key={item.id}
							className={`item ${activeTabIndex === index ? "active" : ""}`}
							onClick={() => setActiveTabIndex(index)}
						>
							<div className="image">
								<ResponsiveImage id={imageAssetId} />
							</div>
							<h3>{item.heading}</h3>
							<p className="subheading">{activeItem.subHeading}</p>
							<p>{item.description}</p>
						</div>
					);
				})}
			</div>
			{activeItem && (
				<div className="active-item">
					<p className="subheading">{activeItem.subHeading}</p>
					<p>{activeItem.description}</p>
				</div>
			)}
		</div>
	);
}

export default Widget(TabbedItems);